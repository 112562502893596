













































import {
  Component,
  Vue,
} from 'vue-property-decorator';

import { format, addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import RankingUser from '@/pages/Ranking/components/RankingUser/RankingUser.vue';

import RankingLoading from './RankingLoading.vue';
import Podium from './Podium/index.vue';

import Joystick from '@/assets/icons/ranking/Joystick.vue';

import GamificationService from '@/services/Gamification/gamificationService';

@Component({
  components: {
    BoxContainer,
    AvatarRanking,
    RankingLoading,
    RankingUser,
    Joystick,
    FeedbackUser,
    Podium,
  },
})
export default class RankingMonth extends Vue {
  private isLoadingRankingMonth = true;

  private rankingMonth: any[] = [];

  private GamificationService = new GamificationService();

  created() {
    this.getRankingMonth();
  }

  get previousMonth() {
    const month = addMonths(new Date(), -1);

    return format(month, 'MMMM', {
      locale: pt,
    });
  }

  async getRankingMonth() {
    try {
      this.isLoadingRankingMonth = true;

      const ranking = await this.GamificationService.getUserRankingMonth();

      this.rankingMonth = [
        {
          ...ranking[1],
          position: 2,
        },
        {
          ...ranking[0],
          position: 1,
        },
        {
          ...ranking[2],
          position: 3,
        },
      ];
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRankingMonth = false;
    }
  }

  redirectPageRanking() {
    this.$router.push({
      name: 'Ranking',
    });
  }
}
