






















import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';

import moment from 'moment';
import { add, getTime } from 'date-fns';

import { OnboardingInterface } from '@/share/Util/Onboarding/Onboarding';
import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

const ID_PLAN_PREMIUM_ANNUAL = '39';
const DISCOUNT = 'expnow';

const TIME_INTERVAL_ONDE_SECONDS_IN_MILLISECONDS = 1000;

@Component
export default class BannerPromotions extends Vue {
  private currentTime = '';
  private timer = 0;

  mounted() {
    this.timeBanner();
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }

  get onboardingsStore() {
    return this.$store.getters.profile?.tests || [];
  }

  get targetDate() {
    const firstAccess = this.findOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS, this.onboardingsStore);

    if (!firstAccess || !firstAccess.user_registry_date) return null;

    const date = add(new Date(firstAccess.user_registry_date), {
      days: 1,
      hours: 3,
    });

    return date;
  }

  @Watch('targetDate')
  async timeBanner() {
    if (!this.targetDate) return;

    const now = new Date();
    const timeDifference = getTime(this.targetDate || 0) - getTime(now);

    if (timeDifference <= 0) {
      this.currentTime = 'Tempo esgotado!';

      clearTimeout(this.timer);
      this.$emit('close');
    }

    this.timer = setTimeout(this.timeBanner, TIME_INTERVAL_ONDE_SECONDS_IN_MILLISECONDS);

    const formattedTime = moment.utc(timeDifference).format('HH[h] mm[m] s[s]');

    this.currentTime = formattedTime || '00H 00m 00s';
  }

  handleRedirectPlanAnnual() {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: ID_PLAN_PREMIUM_ANNUAL,
        discount: DISCOUNT,
      },
    });
  }

  findOnboarding(idOnboarding: number, onboardings: OnboardingInterface[] | []) {
    return onboardings.find((onboarding: OnboardingInterface) => onboarding.ID === idOnboarding);
  }
}
