export const INDEX_OPTION_MENU = {
  CREDIT_CART: 0,
  BILLET: 1,
  PIX: 2,
};

export const PAYMENT_METHODS = [
  {
    id: INDEX_OPTION_MENU.CREDIT_CART,
    name: 'Cartão de Crédito',
    discount: null,
    colorDiscount: null,
    icon: 'CreditCard',
    active: true,
  },
  {
    id: INDEX_OPTION_MENU.BILLET,
    name: 'Boleto',
    discount: 'Desconto de 5%',
    colorDiscount: '#E8219F',
    icon: 'Billet',
    active: false,
  },
  {
    id: INDEX_OPTION_MENU.PIX,
    name: 'PIX',
    discount: 'Desconto de 10%',
    colorDiscount: '#E82145',
    icon: 'Pix',
    active: false,
  },
];

export const TYPE_DISCOUNT = {
  CREDIT_CART: 'credit_card',
  PIX: 'pix',
  BILLET: 'bank_slip',
};

export const METHOD_PAYMENT = {
  [INDEX_OPTION_MENU.CREDIT_CART]: TYPE_DISCOUNT.CREDIT_CART,
  [INDEX_OPTION_MENU.PIX]: TYPE_DISCOUNT.PIX,
  [INDEX_OPTION_MENU.BILLET]: TYPE_DISCOUNT.BILLET,
};

export const METHOD_PAYMENT_TITLE = {
  [INDEX_OPTION_MENU.CREDIT_CART]: 'cartão',
  [INDEX_OPTION_MENU.PIX]: 'pix',
  [INDEX_OPTION_MENU.BILLET]: 'boleto',
};

export const METHOD_PAYMENT_NAME = {
  [TYPE_DISCOUNT.CREDIT_CART]: 'Cartão de Crédito',
  [TYPE_DISCOUNT.PIX]: 'PIX',
  [TYPE_DISCOUNT.BILLET]: 'Boleto',
};

export const METHOD_BY_CREDIT_CART = 'credit_card';
export const METHOD_BY_BILLET = 'bank_slip';
export const METHOD_BY_PIX = 'pix';

export const INTERVAL_MONTHLY = 1;

export const LIST_INTERVAL = [
  {
    title: 'Anual',
    value: 12,
  },
  {
    title: 'Semestral',
    value: 6,
  },
  {
    title: 'Trimestral',
    value: 3,
  },
  {
    title: 'Mensal',
    value: 1,
  },
];

export const PLAN_TYPE_ANNUAL = 12;
export const PLAN_TYPE_SEMIANNUAL = 6;

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  CANCELED: 'canceled',
  PAID: 'paid',
  CREATED: 'created',
};

export const PHYSICAL_PRODUCT_ID = 101;
