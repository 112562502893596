















import { Component, Vue } from 'vue-property-decorator';

import { THEME } from '@/share/Util/Color/PlatformColors';

@Component
export default class LogoExplicae extends Vue {
  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get color() {
    return this.isDarkMode
      ? THEME.theme_dark['neutralcolor-low-pure']
      : THEME.theme_light['primarycolor-pure'];
  }
}
