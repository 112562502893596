







































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';

import store from '@/store';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import RankingUser from '@/pages/Ranking/components/RankingUser/RankingUser.vue';

import RankingLoading from './RankingLoading.vue';

import Joystick from '@/assets/icons/ranking/Joystick.vue';

import GamificationService from '@/services/Gamification/gamificationService';

import { RankingData, User } from '@/globalInterfaces/Gamification';

@Component({
  components: {
    BoxContainer,
    AvatarRanking,
    RankingLoading,
    RankingUser,
    Joystick,
    FeedbackUser,
  },
  filters: {
    formatName(user: User) {
      if (!user) return '';

      const { idstudent } = store.getters.profile;

      const splitName = user.name.split(' ');

      if (idstudent && user.ID === idstudent) return `${splitName[0]} (você)`;

      return splitName[0];
    },
    formatPosition(value: number) {
      return value > 100 ? '100+' : `${value}`;
    },
  },
})
export default class Ranking extends Vue {
  private isLoadingRanking = true;
  private userBelowTopFive = false;
  private errorRanking = false;

  private userDataRanking: RankingData | null = null;

  private ranking: RankingData[] = [];

  private GamificationService = new GamificationService();

  created() {
    this.getRanking();
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get idStudent() {
    return this.profile?.idstudent;
  }

  async getRanking() {
    try {
      this.isLoadingRanking = true;

      const ranking = await this.GamificationService.getRanking();

      if (ranking?.ranking.length) {
        const isUserInRanking = this.findUserRanking(ranking?.ranking);

        if (!isUserInRanking) {
          const userRanking = await this.GamificationService.getUserRanking();

          ranking.ranking.push(userRanking);
        }
      }

      this.ranking = this.setRanking(ranking?.ranking);
    } catch (error) {
      this.errorRanking = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRanking = false;
    }
  }

  setRanking(ranking: RankingData[]) {
    const newRanking = ranking.filter((userPlacement: RankingData) => {
      if (userPlacement.user.ID === this.idStudent) {
        if (userPlacement.ranking > 5) {
          this.userBelowTopFive = true;
        }

        this.userDataRanking = userPlacement;
      }

      return !this.userBelowTopFive
        || this.userDataRanking?.user.ID !== userPlacement.user.ID;
    });

    return newRanking;
  }

  findUserRanking(ranking: RankingData[]) {
    const userRanking = ranking.find((user) => user.user.ID === this.idStudent);

    return userRanking;
  }

  redirectPageRanking() {
    this.$router.push({
      name: 'Ranking',
    });
  }
}
