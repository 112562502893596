import { NavigationGuardNext, Route } from 'vue-router';

import Permission from './index';
import { LIST_PERMISSION } from '@/constant/ListPermission';

export async function permissionDoubtsCredits(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasCredits = await PermissionClass.can_credits(LIST_PERMISSION.DOUBTS_CREDITS);

  if (!hasCredits) {
    next({
      name: 'Doubts',
    });
  }

  next();
}

export function permissionDoubts(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasPermission = PermissionClass.can(LIST_PERMISSION.DOUBTS);

  if (!hasPermission) {
    next({
      name: 'Doubts',
    });
  }
  next();
}

export function permissionDoubtsFeature(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasPermission = PermissionClass.can(LIST_PERMISSION.DOUBTS_FEATURE);

  if (!hasPermission) {
    next({
      name: 'Dashboard',
    });
  }

  next();
}
