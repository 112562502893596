import { DEFAULT_USERS } from '@/constant/AvatarsExp';

const CRITERION_SPLIT = 'avatars/';
const PREFIX_URL_REDACTION_SAVE = 'uploads/avatars/';
const IMAGE_CORRECT_DOMAIN = 'explicae.com.br';

export const UrlTreatmentSaveVersa = (url: string) => {
  const splitURL = url.split(CRITERION_SPLIT);
  const lastPositionSplit = splitURL.length - 1;

  return `${PREFIX_URL_REDACTION_SAVE}${splitURL[lastPositionSplit]}`;
};

export const setImageProfile = (url: string) => {
  if (!url) return DEFAULT_USERS;

  if (url.includes(IMAGE_CORRECT_DOMAIN)) return url;

  const isPrefixURL = url.includes(PREFIX_URL_REDACTION_SAVE);

  if (isPrefixURL) return `https://explicae.com.br/app/${url}`;

  return url || DEFAULT_USERS;
};
