
























import { Component, Vue } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Quotes from '@/assets/icons/Quotes.vue';

import ParagraphLoading from './ParagraphLoading.vue';

import BookService from '@/services/Book/BookService';

@Component({
  components: {
    BoxContainer,
    FeedbackUser,
    ParagraphLoading,
    Quotes,
  },
})
export default class Paragraph extends Vue {
  private text = '';

  private isLoadingQuotes = false;

  private bookService = new BookService();

  created() {
    this.getQuotes();
  }

  async getQuotes() {
    try {
      this.isLoadingQuotes = true;

      const content = await this.bookService.quotes();

      this.text = content?.content;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a frase motivacional.',
        status: 'error',
      });
    } finally {
      this.isLoadingQuotes = false;
    }
  }
}
