import { LIST_PERMISSION } from './ListPermission';

export const ID_SIMULATED_PREVIOUS = 9;
export const ID_SIMULATED_REVISION = 294;
export const ID_SIMULATED_COURSE = 293;

export const IDS_FOR_PERMISSION_SIMULATED: Record<number, Array<string>> = {
  [ID_SIMULATED_PREVIOUS]: [LIST_PERMISSION.SIMULATIONS_PREVIOUS],
  [ID_SIMULATED_REVISION]: [LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM],
  [ID_SIMULATED_COURSE]: [LIST_PERMISSION.SIMULATIONS_COURSES],
};

export const LIST_PERMISSION_SIMULATED = [
  LIST_PERMISSION.SIMULATIONS_PREVIOUS,
  LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM,
  LIST_PERMISSION.SIMULATIONS_COURSES,

];

export const SIMULADO_REVISAO = 'simulado-revisao';
export const SIMULADO_VESTIBULARES_ANTERIORES = 'vestibulares-anteriores';
export const SIMULADO_CURSOS = 'simulados-dos-cursos';
