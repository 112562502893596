




























































import { Component, Prop, Vue } from 'vue-property-decorator';

import store from '@/store';

import AvatarRanking from '@/components/AvatarRanking/index.vue';

import ExpIcon from '@/assets/icons/Exp.vue';

import { RankingData, User } from '@/globalInterfaces/Gamification';

@Component({
  components: {
    AvatarRanking,
    ExpIcon,
  },
  filters: {
    formatName(user: User) {
      if (!user) return '';

      const { idstudent } = store.getters.profile;

      const splitName = user.name.split(' ');

      if (idstudent && user.ID === idstudent) return `${splitName[0]} (você)`;

      return splitName[0];
    },
    formatPosition(value: number) {
      return value > 100 ? '100+' : `${value}`;
    },
  },
})
export default class RankingUser extends Vue {
  @Prop({ required: true }) ranking!: RankingData;
}
