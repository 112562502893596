import { render, staticRenderFns } from "./BannerPromotions.vue?vue&type=template&id=05819808&scoped=true&"
import script from "./BannerPromotions.vue?vue&type=script&lang=ts&"
export * from "./BannerPromotions.vue?vue&type=script&lang=ts&"
import style0 from "./BannerPromotions.scss?vue&type=style&index=0&id=05819808&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05819808",
  null
  
)

export default component.exports