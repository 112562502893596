







































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

const ID_PLAN_PREMIUM_ANNUAL = '39';

@Component
export default class Banner extends Vue {
  @Prop() text!: string;
  @Prop({ default: true }) showImage!: boolean;

  handleRedirectPlan() {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: ID_PLAN_PREMIUM_ANNUAL,
      },
    });
  }

}
