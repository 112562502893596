import store from '@/store';

import AppClient from '../AppClient';

import { Student } from './StatsInterface';

import { currentMonth, currentYear } from '@/share/Util/Dates/index';

export default class StatsService {
  private basePath = process.env.VUE_APP_STATS_API;

  private Client = new AppClient({ url: this.basePath });

  async getStudent({ userId, month = currentMonth, year = currentYear }: Student) {
    const URI = '/users';

    const response = await this.Client.get(URI, {
      userId,
      month,
      year,
    });

    return response?.data;
  }

  async getRealtimeUsers() {
    const { usersOnline } = store.getters;

    if (usersOnline?.users?.length) return usersOnline;

    const URI = '/v2/users/online';

    const response = await this.Client.get(URI);

    store.commit('setUsersOnline', response?.data);

    return response?.data;
  }
}
