



























































































import { Component, Vue, Watch } from 'vue-property-decorator';

import { Expo, gsap } from 'gsap';
import {
  differenceInDays,
  endOfDay,
  compareAsc,
  addHours,
  getTime,
  add,
} from 'date-fns';

import SplashScreen from '@/components/SplashScreen/SplashScreen.vue';
import NavMenu from '@/components/NavSidebar/NavSidebar.vue';
import ModalReportProblem from '@/components/ButtonReportProblem/ModalReportProblem.vue';
import ModalCourse from '@/components/Modal/ModalCourse/ModalCourse.vue';
import ModalNotContentPlan from '@/components/Modal/ModalNotContentPlan/ModalNotContentPlan.vue';
import ModalExitSimulated from '@/components/Modal/ModalExitSimulation/ModalExitSimulation.vue';
import ExToastify from '@/components/Toastify/Toastify.vue';
import ModalTrialPlan from '@/components/Modal/ModalTrialPlan/ModalTrialPlan.vue';
import ModalWelcomePlanTrial from '../components/Modal/ModalWelcomePlanTrial/ModalWelcomePlanTrial.vue';
import ModalPlanUpdate from '@/components/Modal/ModalPlanUpdate/ModalPlanUpdate.vue';
import ModalEndOfPlanTrial from '@/components/Modal/ModalEndOfPlanTrial/ModalEndOfPlanTrial.vue';
import ModalWelcome from '@/components/Modal/ModalWelcome/ModalWelcome.vue';
import ModalWelcomeFeature from '@/components/Modal/ModalWelcomeFeature/ModalWelcomeFeature.vue';
import ModalEndFeature from '@/components/Modal/ModalEndFeature/ModalEndFeature.vue';
import ModalPopUpPlanFree from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFree.vue';
import ModalContentFree from '@/components/Modal/ModalContentFree/index.vue';
import ModalPopUpPlanFreeSimulated from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFreeSimulated.vue';
import ModalPopUpPlanFreeExam from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFreeExam.vue';
import ModalCredits from '@/components/Modal/ModalCredits/index.vue';
import ModalRankingPass from '@/components/Modal/ModalRankingPass/index.vue';
import BannerPromotions from '@/components/BannerPromotions/BannerPromotions.vue';
import BannerRenewPlan from '@/components/BannerRenewPlan/BannerRenewPlan.vue';

import FloatingButton from '@/components/FloatingButtons/index.vue';

import animationData from '@/assets/animations/loadingBook.json';

import PaymentService from '@/services/Payment/PaymentService';

import Onboarding, { OnboardingInterface } from '@/share/Util/Onboarding/Onboarding';
import ActiveModal from '@/share/Util/ActiveModal';
import { showScriptSurvio } from '@/share/Util/Scripts/Survio';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { ROUTER_PAGE_RENEW_PLAN } from '@/constant/Payment';

import { PAYMENT_STATUS } from '@/pages/Shopping/constants/index';

import { PaymentSuccess } from '../pages/Shopping/interfaces/IPayment';

import ActiveModalRankingPass from '../mixins/ActiveModalRankingPass';

interface Permission {
  ID: number;
  end_date: string | Date;
  start_date: string | Date;
  isExtraPermission?: boolean;
  name: string;
}

const ZERO_DAY_REMAINING = 0;
const SEVEN_DAY_REMAINING = 7;

// const ROUTER_PAGE_NAME_BOOK = 'BooksView';
// const ROUTES_PAGES_EXAM = ['Exams', 'Exam', 'ExamViewer'];
// const ROUTES_PAGES_SIMULATED = ['SimulationsList', 'SimulatedInstruction', 'OtherSimulated', 'SimulatedQuestions', 'SimulatedResult', 'EnemSimulator', 'SimulatorResult'];
// const ROUTES_PAGES_REDACTION = ['Redaction', 'TutorialRedaction', 'RedactionInstruction', 'MyRedactions', 'CorrectionRedaction', 'RedactionSend', 'NoteAThousandRedaction'];
// const ROUTER_PAGE_NAME_PLANNER = 'Planner';

@Component({
  components: {
    SplashScreen,
    NavMenu,
    ModalReportProblem,
    ModalCourse,
    ModalNotContentPlan,
    ModalExitSimulated,
    ExToastify,
    ModalTrialPlan,
    ModalWelcomePlanTrial,
    ModalPlanUpdate,
    ModalEndOfPlanTrial,
    ModalWelcome,
    ModalWelcomeFeature,
    ModalEndFeature,
    ModalPopUpPlanFree,
    ModalContentFree,
    ModalPopUpPlanFreeSimulated,
    ModalPopUpPlanFreeExam,
    ModalCredits,
    ModalRankingPass,
    BannerPromotions,
    BannerRenewPlan,
    FloatingButton,
  },
  mixins: [ActiveModalRankingPass],
})
export default class App extends Vue {
  private transitionName = 'slide-left';

  private isAnonymous = false;
  private isShowBannerPromotion = false;
  private isShowBannerRenewPlan = false;
  private isLoadingRenewPlan = false;

  private payment: PaymentSuccess | null = null;

  private modalInstance = new ActiveModal();
  private onboarding = new Onboarding();
  private PaymentService = new PaymentService();

  mounted() {
    this.getRenewPlan();
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get getSidebar() {
    return this.$store.getters.sidebar;
  }

  get getDecreaseSidebar() {
    return this.$store.getters.decreaseSidebar;
  }

  get getIsShowNavbar() {
    // TODO anonymous
    return this.$store.getters.isShowNavbar && this.plan;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get isLogged() {
    return this.$store.getters.profile?.name;
  }

  get appStart() {
    return (
      this.$store.getters.appStart && this.$store.getters.profile?.idstudent
    );
  }

  get daysRemainingPlan() {
    const planData = this.plan;
    const daysRemaining = differenceInDays(
      endOfDay(new Date(planData?.end_date)),
      new Date(),
    );

    return daysRemaining;
  }

  get isPlanTrial() {
    return this.plan?.planID === LIST_PLAN_ID.TRIAL;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get viewedWelcomeModalPlan() {
    if (!this.profile?.name) {
      return false;
    }

    return this.onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS);
  }

  get isFirstAccessDate() {
    const tests = this.$store.getters.profile?.tests;

    const firstAccess = this.findOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS, tests);

    if (!firstAccess || !firstAccess.user_registry_date) return null;

    const date = add(new Date(firstAccess.user_registry_date), {
      days: 1,
      hours: 3,
    });

    return getTime(date) - getTime(new Date()) > 0;
  }

  get routeIsNotFromStore() {
    const { path } = this.$route;

    return !path.includes('loja');
  }

  get showBannerPromotions() {
    return (this.isShowBannerPromotion || this.isFirstAccessDate) && this.isPlanFree && this.routeIsNotFromStore;
  }

  get showModalRenewPlan() {
    const nameRoute = this.$route.name || '';

    return !ROUTER_PAGE_RENEW_PLAN.includes(nameRoute)
      && !this.isLoadingRenewPlan
      && this.isShowBannerRenewPlan
      && this.plan?.expireDate;
  }

  findOnboarding(idOnboarding: number, onboardings: OnboardingInterface[] | []) {
    return onboardings?.find((onboarding: OnboardingInterface) => onboarding.ID === idOnboarding);
  }

  @Watch('daysRemainingPlan')
  setModal() {
    if (
      this.isPlanTrial
      && this.daysRemainingPlan === SEVEN_DAY_REMAINING
      && !this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS_PLAN_TRIAL)) {
      this.modalInstance.activeModal('ModalWelcomePlanTrial');
      return;
    }

    if (this.daysRemainingPlan === ZERO_DAY_REMAINING && this.isPlanTrial) {
      this.modalInstance.activeModal('ModalTrialPlan');
      return;
    }

    if (
      this.isPlanFree
      && !this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)
      && this.isPermissionTemporary()
    ) {
      this.modalInstance.activeModal('ModalWelcomeFeature');
      return;
    }

    if (!this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)) {
      this.modalInstance.activeModal('ModalWelcome');
      return;
    }

    if (
      this.isPlanFree
      && this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)
      && !this.viewedModal(LIST_ONBOARDING_ID.PERMISSION_TIMEOUT)
      && this.isFirstPermissionTemporaryOver()
    ) {
      this.modalInstance.activeModal('ModalEndFeature');
    }
  }

  @Watch('isPlanFree', { immediate: true })
  setScriptSurvio() {
    if (this.isPlanFree) showScriptSurvio();
  }

  isPermissionTemporary() {
    const permissions = this.$store.getters.profile?.permissions ?? [];

    if (!permissions.length) return false;

    return permissions.some((permission: Permission) => permission?.isExtraPermission);
  }

  isFirstPermissionTemporaryOver() {
    const permissions = this.$store.getters.profile?.permissions ?? [];

    if (!permissions.length) return false;

    const foundPermission = permissions.sort((permissionFirst: Permission, permissionSecond: Permission) => {
      const permissionFirstDate = new Date(permissionFirst?.start_date);
      const permissionSecondDate = new Date(permissionSecond?.start_date);

      return permissionFirstDate.getTime() - permissionSecondDate.getTime();
    }).find((permission: Permission) => permission?.isExtraPermission);

    const isOverPermission = compareAsc(addHours(new Date(foundPermission?.end_date), 3), new Date()) === -1;

    return foundPermission ? isOverPermission : false;
  }

  @Watch('$sidebar.contentInstance')
  onInstance() {
    this.removeAllChildNodes(this.$refs.sidebarcontent);
    // @ts-ignore
    this.$refs.sidebarcontent.appendChild(this.$sidebar.contentInstance.$el);
  }

  viewedModal(IdModal: number) {
    if (!this.profile?.name) {
      return false;
    }
    return this.onboarding.alreadyViewedOnboarding(IdModal);
  }

  removeAllChildNodes(parent: any) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  @Watch('appStart')
  hidePreload() {
    this.removeSplashScreen();
  }

  @Watch('$route')
  init() {
    const nameRoute = this.$route.name || '';

    this.isAnonymous = this.$route.meta?.isAnonymous;

    if (nameRoute === '404') this.removeSplashScreen();

    this.setModal();

    // if (this.isPlanFree) {
    //   if (nameRoute === ROUTER_PAGE_NAME_BOOK) {
    //     this.modalInstance.activeModal('ModalContentFree');
    //     return;
    //   }

    //   if (ROUTES_PAGES_EXAM.includes(nameRoute)) {
    //     this.modalInstance.activeModal('ModalPopUpPlanFreeExam');
    //     return;
    //   }

    //   if (ROUTES_PAGES_SIMULATED.includes(nameRoute)) {
    //     this.modalInstance.activeModal('ModalPopUpPlanFreeSimulated');
    //     return;
    //   }

    //   if (ROUTES_PAGES_REDACTION.includes(nameRoute) || ROUTER_PAGE_NAME_PLANNER === nameRoute) {
    //     return;
    //   }

    //   if (
    //     ROUTES_PAGES_REDACTION.includes(nameRoute)
    //               || ROUTER_PAGE_NAME_PLANNER === nameRoute
    //               || ROUTER_PAGE_NAME_SHOPPING.includes(nameRoute)
    //   ) {
    //     return;
    //   }

    //   this.modalInstance.activeModal('ModalPopUpPlanFree');
    // }
  }

  movieClick(): void {
    this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
  }

  removeSplashScreen() {
    gsap.to('#splash-screen', { opacity: 0, duration: 2, ease: Expo.easeIn }).then(() => {
      const element = document.querySelector('#splash-screen');
      element?.parentNode?.removeChild(element); // eslint-disable-line
    });
  }

  handleStartBanner() {
    this.isShowBannerPromotion = true;
  }

  handleCloseBannerPromotion() {
    this.isShowBannerPromotion = false;
  }

  handleCloseButton() {
    this.isShowBannerRenewPlan = false;
  }

  async getRenewPlan() {
    try {
      this.isLoadingRenewPlan = true;

      const [response] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS.CREATED,
      });

      if (response) {
        this.payment = response;

        this.$store.commit('setRenewPlanPayment', response);

        this.isShowBannerRenewPlan = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingRenewPlan = false;
    }
  }
}
