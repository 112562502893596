import Vue from 'vue';
import Vuex from 'vuex';
import { AES, enc } from 'crypto-ts';

import { DEFAULT_USERS } from '@/constant/AvatarsExp';

import cookieUtil from '@/services/Util/cookieUtil';

import exercise from './Exercise/exercise';
import historic from './Historic/historic';
import AnnotationsOrder from './AnnotationsOrder/AnnotationsOrder';
import Profile from './Profile/profile';
import Book from './Book/Book';
import Exams from './Exam/Exams';
import Course from './Course/Course';
import Planner from './Planner/Planner';
// eslint-disable-next-line import/no-cycle
import PlannerAccordion from './Planner/PlannerAccordion';
import Files from './Files/Files';
import FilterVideos from './FilterVideos/FilterVideos';
import RouterHistory from './RouterHistory/RouterHistory';
import Toast from './Toast/Toast';
import Redaction from './Redaction/Redaction';
import Plan from './Plan/Plan';
import Simulated from './Simulated/Simulated';
import Simulator from './Simulated/Simulator';
import Navbar from './Navbar/Navbar';
import BreadCrumb from './BreadCrumb/BreadCrumb';
import Doubts from './Doubts/Doubts';
import MaterialCategory from './MaterialCategory/MaterialCategory';
import Theme from './Theme';
import Video from './Video';
import Modal from './Modal/Modal';
import RenewPlan from './RenewPlan/RenewPlan';
import Books from './Books/index';
import Degrees from './Degrees/index';
import Shopping from './Shopping/Shopping';
import Payment from './Payment/Payment';
import Question from './Question/Question';
import Permission from './Permission/Permission';
import Users from './Users/Users';

Vue.use(Vuex);
const keyCrypt = 'R@109';
const modules = {
  Book,
  exercise,
  historic,
  AnnotationsOrder,
  Profile,
  Exams,
  Course,
  Planner,
  PlannerAccordion,
  Plan,
  Files,
  FilterVideos,
  RouterHistory,
  Toast,
  Redaction,
  Simulated,
  Simulator,
  Navbar,
  BreadCrumb,
  Doubts,
  MaterialCategory,
  Theme,
  Video,
  Books,
  Degrees,
  Modal,
  RenewPlan,
  Shopping,
  Payment,
  Question,
  Permission,
  Users,
};

// function addUser(state: any, picture: string) {
//   if (picture === 'avatar.png' || picture === null || picture === '') return;
//   const clippingPic = picture.split('uploads');
//   const photo = `${process.env.VUE_APP_API_STATIC_CDN}${clippingPic[clippingPic.length - 1]}?w=60&h=60`;// photo; /// list[i];
//   let add = true;
//   state.users.forEach((data: string) => {
//     if (data === photo) add = false;
//   });
//   if (add) state.users.unshift(photo);
// }

export default new Vuex.Store({
  modules,
  state: {
    isLoading: false,
    filtered: {
      videos: true,
      archives: true,
      attachments: true,
      exercices: true,
      lives: true,
    },
    topic: {},
    file: {},
    teachers: [],
    simulated: [],
    decreaseSidebar: false,
    tabs: [],
    appStart: false,
    pendingRoute: {},
    sidebar: false,
    sidebarExistence: false,
    isMobile: false,
    isMovie: false,
    token: '',
    profile: {
      nickname: '',
      name: '',
    },
    avatar: '',
    theme: {
      textColor: '#FFFFFF',
      bgColor: '#9204d2',
      background: 'https://cdn.explicae.com.br/public/assets/themes/bg/roxo.jpg',
    },
  },
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setAppStart(state, payload) {
      state.appStart = payload;
    },
    setPendingRoute(state, payload) {
      state.pendingRoute = payload;
    },
    setSidebar(state, payload: boolean) {
      state.sidebar = payload;
    },
    setisMobile(state, payload: boolean) {
      state.isMobile = payload;
    },
    setIsMovie(state, payload: boolean) {
      state.isMovie = payload;
    },
    setSidebarExistence(state, payload: boolean) {
      state.sidebarExistence = payload;
    },
    setDecreaseSidebar(state, payload: boolean) {
      state.decreaseSidebar = payload;
    },
    setTabs(state, payload) {
      if (payload) {
        state.tabs = payload;
      }
    },
    initialState(state) {
      // @ts-ignore
      state.token = localStorage.getItem('token') || cookieUtil.getCookie('auth_token');
      const loadLSSimulated: string | null = localStorage.getItem('ex-simulated');
      if (loadLSSimulated !== null) {
        try {
          state.simulated = JSON.parse(AES.decrypt(loadLSSimulated, keyCrypt).toString(enc.Utf8));
        } catch (e) {
          state.simulated = [];
        }

      }
    },
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem('token', payload);
    },
    setSimulated(state, payload) {
      state.simulated = payload;
      localStorage.setItem('ex-simulated', AES.encrypt(JSON.stringify(payload), keyCrypt).toString());
    },
    setFiltered(state, payload) {
      state.filtered = payload;
    },
    setTeachers(state, payload) {
      state.teachers = payload;
    },
    setTopic(state, payload) {
      state.topic = payload;
    },
    setFile(state, payload) {
      state.file = payload;
    },

  },
  actions: {
    setProfile({ commit, state }, payload) {
      state.profile = {
        ...state.profile,
        ...payload,
      };
      state.profile = payload;
      state.avatar = payload.avatar || state.avatar || DEFAULT_USERS;
      state.profile.nickname = payload.nickname || payload.name || state.profile.nickname || state.profile.name || 'Sem nome';
      commit('setTitlePlan', (payload.plan) ? payload.plan.title : 'Sem plano');
    },
  },
  getters: {
    topic: (state) => state.topic,
    file: (state) => state.file,
    isLoading: (state) => state.isLoading,
    pendingRoute: (state) => state.pendingRoute,
    appStart: (state) => state.appStart,
    sidebar: (state) => state.sidebar,
    sidebarExistence: (state) => state.sidebarExistence,
    isMobile: (state) => state.isMobile,
    isMovie: (state) => state.isMovie,
    theme: (state) => state.theme,
    decreaseSidebar: (state) => state.decreaseSidebar,
    tabs: (state) => state.tabs,
    profile: (state) => state.profile,
    token: (state) => state.token,
    avatar: (state) => state.avatar,
    filtered: (state) => state.filtered,
    teachers: (state) => state.teachers,
    simulated: (state) => state.simulated,
  },
});
