import AppClient from '../AppClient';

import { GetRanking, GetUserRankingMonth } from './gamificationInterface';

import { RankingMonth } from '@/globalInterfaces/Gamification';

export default class GamificationService {
  private basePath = process.env.VUE_APP_HISTORIC_API;
  private basePathStats = process.env.VUE_APP_STATS_API;

  private Client = new AppClient({ url: this.basePath });
  private ClientStats = new AppClient({ url: this.basePathStats });

  async getRanking(data?: GetRanking) {
    const URI = '/ranking';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async getUserRanking() {
    const URI = '/ranking/user';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getLevels() {
    const URI = '/ranking/levels';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getUserRankingMonth(data?: GetUserRankingMonth): Promise<RankingMonth[]> {
    const URI = '/users/ranking';

    const response = await this.ClientStats.get(URI, data);

    return response?.data;
  }
}
