








































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import store from '@/store';

import AvatarRanking from '@/components/AvatarRanking/index.vue';
import Confetti from '@/components/Confetti/index.vue';
import ExpIcon from '@/assets/icons/Exp.vue';

const CLASS_POSITION: Record<number, string> = {
  1: '--first',
  2: '--second',
  3: '--third',
};

@Component({
  components: {
    AvatarRanking,
    ExpIcon,
    Confetti,
  },
  filters: {
    formatName(name: string, userID: number) {
      if (!userID) return '';

      const { idstudent } = store.getters.profile;

      const splitName = name.split(' ');

      if (idstudent && userID === idstudent) return `${splitName[0]} (você)`;

      return splitName[0];
    },
  },
})
export default class Podium extends Vue {
  @Prop({ required: true }) color!: string;
  @Prop({ required: true }) avatar!: string;
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) points!: number;
  @Prop({ required: true }) position!: number;

  get classPosition() {
    return CLASS_POSITION[this.position];
  }
}
