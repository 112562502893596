/* eslint-disable import/no-cycle */
import store from '@/store';

import AppClient from '../AppClient';

import {
  HistoricInterface,
  Historic,
  LastVideoWatched,
  MyListVideoFavorite,
  MyListVideosWatched,
} from './historicInterface';

import { LastAccessedContentCourse } from '@/globalInterfaces/Course';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
// import { LIST_PLAN_ID } from '@/constant/PlansIDs';

export default class HistoricService {
  private basePath = process.env.VUE_APP_HISTORIC_API;

  private Client = new AppClient({ url: this.basePath });

  async getLastVideoWatched(data: LastVideoWatched) {
    const {
      context = LIST_NAME_CONTEXT.BOOK,
      // isFree = false,
    } = data;

    const URI = '/video/last/watched';

    const response = await this.Client.get(URI, { context });

    return response?.data;
  }

  async getMyListVideoFavorite(data: MyListVideoFavorite) {
    const {
      limit = 10,
      page = 1,
      bookSlug,
      // isFree,
    } = data;

    let URI = '/video/list/favorite';

    if (bookSlug) URI = `${URI}?book=${bookSlug}`;

    const response = await this.Client.get(URI, {
      limit,
      page,
      // isFree,
    });

    return response?.data;
  }

  async getMyListVideosWatched(data: MyListVideosWatched) {
    const {
      limit = 10,
      bookSlug,
      // isFree,
    } = data;

    let URI = '/video/list/watched';

    if (bookSlug) URI = `${URI}?book=${bookSlug}`;

    const response = await this.Client.get(URI, { limit });

    return response?.data;
  }

  async deleteVideoHistoric() {
    const URI = '/video/historic/reset';

    const response = await this.Client.patch(URI);

    return response?.data;
  }

  async saveCourseAccessWeek(dataCouse: LastAccessedContentCourse) {
    const URI = '/course/access/week';

    const response = await this.Client.put(URI, dataCouse);

    store.commit('setCourseAccessWeek', dataCouse);

    return response;
  }

  async getCourseAccessWeek(idCourse?: number) {
    const URI = `/course/access/week${idCourse ? `?idCourse=${idCourse}` : ''}`;

    const { courseAccessWeek } = store.getters;

    if (courseAccessWeek
      && (courseAccessWeek.idCourses === idCourse
      || !idCourse)
    ) return courseAccessWeek;

    const response = await this.Client.get(URI);

    if (!idCourse) store.commit('setCourseAccessWeek', response?.data);

    return response?.data;
  }

  async saveHistoric(data: HistoricInterface) {
    // const plan = store.getters.profile?.plan;
    // const isPlanFree = plan?.planID === LIST_PLAN_ID.FREE;

    const newData = {
      ...data,
      isFree: false,
    };

    const URI = '/historic';

    const response = await this.Client.post(URI, newData);

    return response;
  }

  async getHistoric(data: Historic) {
    const URI = '/content/historic';

    const response = await this.Client.get(URI, {
      type: data.type,
      listIds: data.listIds,
    });

    return response?.data;
  }

  async getHistoricClassesWatched(idCourse: string, languageId: number | null) {
    let URI = `/content/historic/course/watched/${idCourse}`;

    if (languageId) {
      URI = `${URI}?languageId=${languageId}`;
    }

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getLastQuestionsViewed(limit?: number) {
    const URI = '/questions/viewed';
    const response = await this.Client.get(URI, { limit });
    return response?.data;
  }
}
